import React from 'react'
import { constants } from '~/config'
import { Container } from './styles'

const Category = ({ id, title, image }) => {
  return (
    <Container to={`/produtos/${id}`}>
      <img src={`${constants.images}categoria/${image}`} />
      <div className="filter">
        <div className="title">{title}</div>
      </div>
    </Container>
  )
}

export default Category
