import styled from 'styled-components'
import { colors } from '~/config'
import { Link } from 'react-router-dom'

export const Container = styled(Link)`
  width: 320px;
  height: 270px;
  display: flex;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .filter {
    padding: 20px;
    width: 320px;
    height: 270px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(244,243,241,0) 0%, rgba(255,255,255, 0.8) 100%);
    position: absolute;
    top: 0;
    left: 0;
    > .title {
      font-size: 20px;
      color: ${colors.secondary};
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: -1px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: 20px;
      display: block;
    }
  }
`
