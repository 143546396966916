import React from 'react'

import { Container } from './styles'

const Maps = () => {
  return (
    <Container>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d59672.32034269273!2d-49.52027370807682!3d-20.81069731367248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x94bdae545316a46b%3A0x10e26979f0d5d831!2sGelius+M%C3%B3veis+Mirassol+-+SP%2C+15130-000!3m2!1d-20.8107811!2d-49.4852543!5e0!3m2!1spt-BR!2sbr!4v1548259528621"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>
    </Container>
  )
}

export default Maps
