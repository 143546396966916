import React, { useEffect, useState } from 'react'
import {
  Header,
  SocialBar,
  Banner,
  Categories,
  Title,
  Localization,
  Wrapper,
  Footer,
  Catalog
} from '~/components'
import { noveltyService } from '~/http/service'
import { constants } from '~/config'
import Catalogs from './Catalogs'
import Contact from './Contact'
import { Container } from './styles'
import { useLoading } from '~/context/Loading'

export const Home = () => {
  const [novelties, setNovelties] = useState([])
  const { setLoading } = useLoading()

  useEffect(() => {
    getNovelties()
  }, [])

  const getNovelties = async () => {
    setLoading(true)
    const response = await noveltyService.getNovelties()
    const novelties = response.data.texto
    setNovelties(novelties.filter(novelty => novelty.kids === 'SIM'))
    setLoading(false)
  }

  return (
    <Container>
      <SocialBar />
      <Header />
      <Banner />

      <Wrapper>
        <Title>
          VEJA NOSSOS <b>PRODUTOS</b>
        </Title>
        <Categories />

        <Title id="catalogos">
          NOSSOS <b>CATALOGOS</b>
        </Title>
        <Catalogs />

        <Title>
          CONFIRA AS <b>NOVIDADES</b>
        </Title>
        {novelties.map((novelty, index) => {
          return (
            <Catalog
              key={index}
              title={novelty.nome}
              description={novelty.descricao}
              url={novelty.url}
              image={constants.images + 'catalogo/' + novelty.imagem}
            />
          )
        })}

        <Localization />

        <Contact />
      </Wrapper>
      <Footer/>
    </Container>
  )
}
