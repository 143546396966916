import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: #fff;
  padding: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid #eee;
  z-index: 99999;
  > p {
    font-size: 14px;
    > a {
      color: #000;
      text-decoration: underline;
    }
  }
  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    > button {
      padding: 10px 25px;
      background: #000;
      color: #fff;
      cursor: pointer;
      &.decline {
        background: #eee;
        color: #555;
      }
    }
  }
`
