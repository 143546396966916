import React from 'react'
import { Header, SocialBar, Banner, Title, Footer } from '~/components'
import { Container } from './styles'

export const Terms = () => {
  return (
    <Container>
      <SocialBar />
      <Header />
      <Banner />
      <div className="content">
<p className="p1"><strong>POL&Iacute;TICA DE PRIVACIDADE</strong></p>
<p className="p2">&nbsp;</p>
<p className="p4">&nbsp;A <strong>GELIUS IND&Uacute;STRIA DE M&Oacute;VEIS LTDA.</strong> possui destaque dentro do mercado moveleiro como uma das mais importantes ind&uacute;strias do pa&iacute;s. Atuando desde 1984 com esfor&ccedil;o e dedica&ccedil;&atilde;o para prover um atendimento adequado aos mais altos n&iacute;veis de exig&ecirc;ncia e qualifica&ccedil;&atilde;o, buscando sempre solu&ccedil;&otilde;es inteligentes para satisfa&ccedil;&atilde;o dos seus clientes.</p>
<p className="p4">A extens&atilde;o desta rela&ccedil;&atilde;o tamb&eacute;m encontra espa&ccedil;o no ambiente digital, no qual mantemos os mesmos princ&iacute;pios e responsabilidades que guiaram nossa trajet&oacute;ria at&eacute; aqui.<span className="Apple-converted-space">&nbsp;</span></p>

<p className="p4">Por esta raz&atilde;o, a empresa leva a sua privacidade e a prote&ccedil;&atilde;o dos seus dados a s&eacute;rio. Este documento reitera o comprometimento leg&iacute;timo da <strong>GELIUS INDUSTRIA DE M&Oacute;VEIS LTDA., </strong>com seus clientes e visitantes e explica o tratamento de seus dados pessoais, quais s&atilde;o os seus direitos e como voc&ecirc; pode exerc&ecirc;-los.</p>

<p className="p4">Nossa sede do Brasil est&aacute; localizada na Avenida Modesto Jos&eacute; Moreira Junior, 4545 - Bairro Portal, CEP 15133-272, Mirassol-SP<span className="Apple-converted-space">&nbsp;</span></p>

<p className="p6"><strong>PRIVACIDADE &Eacute; UM VALOR IMPRESCIND&Iacute;VEL PARA N&Oacute;S!</strong></p>
<p className="p6">Este documento pode ser impresso para fins de consulta atrav&eacute;s do comando imprimir nas configura&ccedil;&otilde;es de seu navegador.&nbsp;</p>
<p className="p6">&nbsp;</p>
<table className="t1" cellSpacing="0" cellPadding="0">
<tbody>
<tr>
<td className="td1" valign="middle">
<p className="p8"><span className="s1">&nbsp;</span><strong>RESPONS&Aacute;VEL PELO TRATAMENTO<span className="Apple-converted-space">&nbsp;</span></strong></p>
<p className="p8"><span className="s1"><span className="Apple-converted-space">&nbsp; </span></span>GELIUS INDUSTRIA DE M&Oacute;VEIS LTDA<span className="Apple-converted-space">&nbsp;</span></p>
<p className="p8"><span className="Apple-converted-space">&nbsp; </span>CNPJ/MF n&ordm; 53.128.781/0001-60<span className="Apple-converted-space">&nbsp;</span></p>
<p className="p9"><span className="Apple-converted-space">&nbsp;&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p className="p10">&nbsp;</p>
<p className="p4"><strong>1. CONCEITOS B&Aacute;SICOS: O QUE VOC&Ecirc; PRECISA SABER PARA COMPREENDER ESTA POL&Iacute;TICA?</strong></p>
<p className="p4">A fim de descomplicar a leitura deste documento, apresentamos algumas defini&ccedil;&otilde;es &uacute;teis para a sua interpreta&ccedil;&atilde;o:</p>

<table className="t1" cellSpacing="0" cellPadding="0">
<tbody>
<tr>
<td className="td1" valign="middle">
<p className="p11"><strong>Termo</strong></p>
</td>
<td className="td1" valign="middle">
<p className="p11"><strong>Conceito</strong></p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11"><strong>Dado Pessoal</strong></p>
</td>
<td className="td1" valign="middle">
<p className="p12">&Eacute; uma informa&ccedil;&atilde;o relacionada a uma pessoa f&iacute;sica e que seja capaz de identificar a pessoa ou tornar poss&iacute;vel a sua identifica&ccedil;&atilde;o.</p>
<p className="p13">&nbsp;</p>
<p className="p12">S&atilde;o exemplos de dados pessoais que podem permitir a sua identifica&ccedil;&atilde;o: Nome, CPF, telefone, e-mail, placa do seu ve&iacute;culo, etc.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11"><strong>Tratamento</strong></p>
</td>
<td className="td1" valign="middle">
<p className="p12">&Eacute; toda forma de uso que podemos fazer dos seus Dados Pessoais, incluindo, mas n&atilde;o se limitando, &agrave;s seguintes atividades: coleta, armazenamento, consulta, uso, compartilhamento, transmiss&atilde;o, classNameifica&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, exclus&atilde;o e avalia&ccedil;&atilde;o.</p>
<p className="p12">Este termo (e outros derivados dele) n&atilde;o ser&atilde;o utilizados com letras mai&uacute;sculas neste documento.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11"><strong>Titular</strong></p>
</td>
<td className="td1" valign="middle">
<p className="p12">&Eacute; voc&ecirc;, a pessoa f&iacute;sica a quem os Dados Pessoais se referem.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11"><strong>Empresa</strong></p>
</td>
<td className="td1" valign="middle">
<p className="p12">Somos n&oacute;s, a <strong>GELIUS INDUSTRIA DE M&Oacute;VEIS LTDA.</strong></p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11"><strong>Pol&iacute;tica</strong></p>
</td>
<td className="td1" valign="middle">
<p className="p12">&Eacute; esta Pol&iacute;tica de Privacidade.</p>
</td>
</tr>
</tbody>
</table>

<p className="p4"><strong>2. QUE TIPOS DE DADOS PESSOAIS A EMPRESA TRATA?</strong></p>
<p className="p4">Os tipos de Dados Pessoais e a forma como a Empresa os coleta atendem aos princ&iacute;pios da transpar&ecirc;ncia, finalidade e legitimidade. Temos o intuito de oferecer melhorias em nossos servi&ccedil;os jur&iacute;dicos e utilizamos <span className="s2"><strong>dados fornecidos diretamente por voc&ecirc;</strong></span>.</p>

<p className="p4">Listamos abaixo as situa&ccedil;&otilde;es em que n&oacute;s podemos tratar seus Dados Pessoais:<span className="Apple-converted-space">&nbsp;</span></p>

<table className="t1" cellSpacing="0" cellPadding="0">
<tbody>
<tr>
<td className="td1" valign="middle">
<p className="p14">Finalidades de Tratamento</p>
</td>
<td className="td1" valign="middle">
<p className="p14">Tipos de Dados Pessoais Utilizados</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p15">&nbsp;</p>
<ul className="ul1">
<li className="li16"><strong>Identificar</strong> Voc&ecirc; <strong>para responder as suas solicita&ccedil;&otilde;es efetuadas em nossos canais de atendimento, em nossa aba <em>Contato</em>.</strong></li>
<li className="li16"><strong>Identificar</strong> Voc&ecirc;, <strong>conforme sua solicita&ccedil;&atilde;o, para participa&ccedil;&atilde;o de nossos processos seletivos</strong></li>
</ul>
<p className="p17">&nbsp;</p>
</td>
<td className="td1" valign="middle">
<ul className="ul1">
<li className="li18"><strong>Nome<span className="Apple-converted-space">&nbsp;</span></strong></li>
<li className="li18"><strong>E-mail</strong></li>
<li className="li18"><strong>Curr&iacute;culo</strong></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p className="p19">&nbsp;</p>
<p className="p4">Importante lembrar que <span className="s2"><strong>voc&ecirc;</strong></span> &eacute; o respons&aacute;vel pela veracidade e precis&atilde;o dos dados informados e pode sempre nos contatar para retifica-los ou mant&ecirc;-los atualizados.<span className="Apple-converted-space">&nbsp; &nbsp;</span></p>

<p className="p20">N&atilde;o se esque&ccedil;a, que caso opte por cancelar sua inscri&ccedil;&atilde;o para o recebimento de nossas comunica&ccedil;&otilde;es e conte&uacute;do, pode faz&ecirc;-lo via e-mail em <span className="s4">lgpd@gelius.com.br</span>, a qualquer tempo.<span className="Apple-converted-space">&nbsp;</span></p>
<p className="p21">&nbsp;</p>
<p className="p4">Informamos que os Dados Pessoais coletados neste ambiente est&atilde;o sob os nossos cuidados e responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necess&aacute;rios, ser&atilde;o feitos dentro dos limites e prop&oacute;sitos dos neg&oacute;cios descritos nesta Pol&iacute;tica.</p>
<p className="p4">Tamb&eacute;m realizamos o tratamento indireto de Dados Pessoais para prover melhor funcionamento deste <em>website</em>. Estas informa&ccedil;&otilde;es s&atilde;o obtidas por meio de <em>cookies</em> e <em>plug-ins</em> de terceiros enviados ao seu navegador e s&atilde;o estritamente necess&aacute;rios para o funcionamento da plataforma, n&atilde;o sendo poss&iacute;vel identificar o usu&aacute;rio/titular a partir delas.</p>

<table className="t1" cellSpacing="0" cellPadding="0">
<tbody>
<tr>
<td className="td1" valign="middle">
<p className="p22"><strong>Pontuamos que n&atilde;o coletamos qualquer dado sens&iacute;vel por esta plataforma e o nosso conte&uacute;do n&atilde;o &eacute; direcionado &agrave;s crian&ccedil;as e adolescentes, assim n&atilde;o fazemos avalia&ccedil;&atilde;o qualifica&ccedil;&atilde;o ou coleta intencional de seus Dados Pessoais. Se voc&ecirc; tem menos que 18 anos de idade, pedimos que n&atilde;o envie quaisquer dados pessoais por meio do nosso website ou plataformas sem o consentimento expresso e a participa&ccedil;&atilde;o de um respons&aacute;vel.</strong></p>
</td>
</tr>
</tbody>
</table>

<p className="p4"><strong>3. COM QUEM COMPARTILHAMOS OS SEUS DADOS PESSOAIS?</strong></p>
<p className="p4">Seus Dados Pessoais n&atilde;o s&atilde;o comercializados com terceiros e apenas os compartilhamos na propor&ccedil;&atilde;o necess&aacute;ria para as finalidades anteriormente indicadas. Na hospedagem do site com a <strong><em>HostGator</em></strong><em>, </em>al&eacute;m do servidor do <strong><em>Google</em></strong>.<em> <span className="Apple-converted-space">&nbsp;</span></em></p>

<p className="p4">Lembramos que as empresas indicadas atuam enquanto operadora de dados e, portanto, somente podem trata-los para os fins j&aacute; estabelecidos e nos termos desta Pol&iacute;tica.</p>

<p className="p4"><strong>4. QUAIS S&Atilde;O OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?</strong></p>
<p className="p4"><strong>Os Dados Pessoais s&atilde;o seus</strong> e a lei brasileira lhe garante uma s&eacute;rie de direitos relacionados a eles. N&oacute;s estamos comprometidos com o cumprimento desses direitos e, nessa se&ccedil;&atilde;o, vamos explicar quais s&atilde;o esses direitos e como voc&ecirc; pode exerc&ecirc;-los. Veja a seguir:<span className="Apple-converted-space">&nbsp;</span></p>

<table className="t1" cellSpacing="0" cellPadding="0">
<tbody>
<tr>
<td className="td1" valign="middle">
<p className="p11">Seus Direitos</p>
</td>
<td className="td1" valign="middle">
<p className="p11">Explica&ccedil;&atilde;o</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Confirma&ccedil;&atilde;o e Acesso</p>
</td>
<td className="td1" valign="middle">
<p className="p4">Voc&ecirc; pode solicitar &agrave; Empresa a confirma&ccedil;&atilde;o sobre a exist&ecirc;ncia de tratamento dos seus Dados Pessoais para que, em caso positivo, voc&ecirc; possa acess&aacute;-los, inclusive por meio de solicita&ccedil;&atilde;o de c&oacute;pias dos registros que temos sobre voc&ecirc;.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Corre&ccedil;&atilde;o</p>
</td>
<td className="td1" valign="middle">
<p className="p4">Voc&ecirc; pode solicitar a corre&ccedil;&atilde;o dos seus Dados Pessoais caso estes estejam incompletos, inexatos ou desatualizados.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o<span className="Apple-converted-space">&nbsp;</span></p>
</td>
<td className="td1" valign="middle">
<p className="p4">Voc&ecirc; pode solicitar <strong>(a)</strong> a anonimiza&ccedil;&atilde;o dos seus Dados Pessoais desnecess&aacute;rios, de forma que eles n&atilde;o possam mais ser relacionados a voc&ecirc; e, portanto, deixem de ser Dados Pessoais; <strong>(b)</strong> o bloqueio dos seus Dados Pessoais, suspendendo temporariamente a sua possibilidade de os tratarmos para certas finalidades; e <strong>(c)</strong> a elimina&ccedil;&atilde;o dos seus Dados Pessoais, caso em que deveremos apagar todos os seus Dados Pessoais sem possibilidade de revers&atilde;o.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Portabilidade</p>
</td>
<td className="td1" valign="middle">
<p className="p4">Voc&ecirc; pode solicitar que a Empresa forne&ccedil;a os seus Dados Pessoais em formato estruturado e interoper&aacute;vel visando a sua transfer&ecirc;ncia para um terceiro, desde que essa transfer&ecirc;ncia n&atilde;o viole a propriedade intelectual ou segredo de neg&oacute;cios.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Informa&ccedil;&atilde;o sobre o compartilhamento</p>
</td>
<td className="td1" valign="middle">
<p className="p4">Voc&ecirc; tem o direito de saber quais s&atilde;o as entidades p&uacute;blicas e privadas em que ocorre uso compartilhado dos seus Dados Pessoais. Manteremos, no item <strong>3</strong> dessa Pol&iacute;tica, uma indica&ccedil;&atilde;o das nossas rela&ccedil;&otilde;es com terceiros que podem envolver o compartilhamento de Dados Pessoais. Em todo caso, se voc&ecirc; tiver d&uacute;vidas ou quiser mais detalhes, voc&ecirc; tem o direito de nos solicitar essas informa&ccedil;&otilde;es. A depender do requerimento, podemos limitar as informa&ccedil;&otilde;es fornecidas a voc&ecirc;, caso a divulga&ccedil;&atilde;o possa violar a propriedade intelectual ou segredo de neg&oacute;cios.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o consentir</p>
</td>
<td className="td1" valign="middle">
<p className="p4">Voc&ecirc; tem o direito de receber informa&ccedil;&otilde;es claras e completas sobre a possibilidade e as consequ&ecirc;ncias de n&atilde;o fornecer consentimento, quando ele for solicitado. O seu consentimento, quando necess&aacute;rio, deve ser livre e informado. Portanto, sempre que pedirmos seu consentimento, voc&ecirc; ser&aacute; livre para neg&aacute;-lo &ndash; nesses casos, &eacute; poss&iacute;vel que alguns servi&ccedil;os n&atilde;o possam ser prestados.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Revoga&ccedil;&atilde;o do consentimento</p>
</td>
<td className="td1" valign="middle">
<p className="p4">Caso voc&ecirc; tenha consentido com alguma finalidade de tratamento dos seus Dados Pessoais, voc&ecirc; pode sempre optar por retirar o seu consentimento. No entanto, isso n&atilde;o afetar&aacute; a legalidade de qualquer Tratamento realizado anteriormente &agrave; revoga&ccedil;&atilde;o. Se voc&ecirc; retirar o seu consentimento, &eacute; poss&iacute;vel que fiquemos impossibilitados de lhe prestar certos servi&ccedil;os, mas iremos avis&aacute;-lo quando isso ocorrer.</p>
</td>
</tr>
<tr>
<td className="td1" valign="middle">
<p className="p11">Oposi&ccedil;&atilde;o</p>
</td>
<td className="td1" valign="middle">
<p className="p4">A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu consentimento ou um contrato conosco. Nessas situa&ccedil;&otilde;es, somente trataremos seus Dados Pessoais se tivermos motivos leg&iacute;timos para tanto. Caso voc&ecirc; n&atilde;o concorde com alguma finalidade de tratamento dos seus Dados Pessoais, voc&ecirc; poder&aacute; apresentar oposi&ccedil;&atilde;o, solicitando a sua interrup&ccedil;&atilde;o.</p>
</td>
</tr>
</tbody>
</table>

<p className="p11"><strong>AVISOS IMPORTANTES</strong></p>
<p className="p23">&nbsp;</p>
<p className="p4">Para sua seguran&ccedil;a, sempre que voc&ecirc; apresentar uma requisi&ccedil;&atilde;o para exercer seus direitos, <strong>a Empresa poder&aacute; solicitar algumas informa&ccedil;&otilde;es e/ou documentos complementares para que possamos comprovar a sua identidade</strong>, buscando impedir fraudes. Fazemos isso para garantir a seguran&ccedil;a e a privacidade de todos.</p>

<p className="p4">Em alguns casos, <strong>a Empresa pode ter motivos leg&iacute;timos para deixar de atender a uma solicita&ccedil;&atilde;o de exerc&iacute;cio de direitos</strong>. Essas situa&ccedil;&otilde;es incluem, por exemplo, casos em que uma revela&ccedil;&atilde;o de informa&ccedil;&otilde;es espec&iacute;ficas poderia violar direitos de propriedade intelectual ou segredos de neg&oacute;cio ou de terceiros, bem como casos em que pedidos de exclus&atilde;o de dados n&atilde;o possam ser atendidos em raz&atilde;o da exist&ecirc;ncia de obriga&ccedil;&atilde;o de reten&ccedil;&atilde;o de dados, seja para cumprir obriga&ccedil;&otilde;es legais, regulat&oacute;rias ou para possibilitar a defesa da Empresa ou de terceiros em disputas de qualquer natureza.</p>

<p className="p4">Ainda, <strong>algumas solicita&ccedil;&otilde;es podem n&atilde;o ser respondidas de forma imediata</strong>, mas a Empresa se compromete a responder todas as requisi&ccedil;&otilde;es em um prazo razo&aacute;vel e sempre em conformidade com a legisla&ccedil;&atilde;o aplic&aacute;vel.</p>
<p className="p4">Caso voc&ecirc; tenha alguma d&uacute;vida sobre essas quest&otilde;es ou sobre como voc&ecirc; pode exercer seus direitos, fique &agrave; vontade para entrar em contato conosco por meio dos canais informados ao final desta Pol&iacute;tica.</p>

<p className="p4"><strong>5. POR QUANTO TEMPO OS DADOS PESSOAIS SER&Atilde;O ARMAZENADOS?</strong></p>
<p className="p4">A Empresa possui uma pol&iacute;tica de reten&ccedil;&atilde;o de Dados Pessoais alinhada com a lei aplic&aacute;vel. Dados Pessoais s&atilde;o armazenados somente pelo tempo que forem necess&aacute;rios para cumprir com as finalidades para as quais foram coletados, salvo se houver qualquer outra raz&atilde;o para sua manuten&ccedil;&atilde;o como, por exemplo, cumprimento de quaisquer obriga&ccedil;&otilde;es legais, regulat&oacute;rias, contratuais, entre outras permitidas sob a lei.</p>

<p className="p4">Sempre fazemos uma an&aacute;lise t&eacute;cnica para determinar o per&iacute;odo de reten&ccedil;&atilde;o adequado para cada tipo de Dado Pessoal coletado, considerando a sua natureza, necessidade de coleta e finalidade para a qual ele ser&aacute; tratado, bem como eventuais necessidades de reten&ccedil;&atilde;o para o cumprimento de obriga&ccedil;&otilde;es ou o resguardo de direitos.</p>

<p className="p4"><strong>6. COMO PROTEGEMOS SEUS DADOS PESSOAIS?</strong></p>
<p className="p4"><strong>Nossa responsabilidade &eacute; cuidar dos seus Dados Pessoais e utiliz&aacute;-los somente para as finalidades descritas nesta Pol&iacute;tica</strong>. Para garantir a sua privacidade e a prote&ccedil;&atilde;o dos seus Dados Pessoais, adotamos recursos tecnol&oacute;gicos avan&ccedil;ados para garantir a seguran&ccedil;a de todos os dados tratado. Entre as medidas de seguran&ccedil;a implementadas est&atilde;o a implementa&ccedil;&atilde;o de controle de acesso dos usu&aacute;rios e ambientes de tratamento de dados e a instala&ccedil;&atilde;o de barreiras contra o acesso indevido &agrave;s bases de dados (incluindo <em>firewalls, Antiv&iacute;rus</em>), entre outros controles de seguran&ccedil;a da informa&ccedil;&atilde;o.</p>

<p className="p4"><strong>N&oacute;s nos esfor&ccedil;amos para proteger a privacidade de seus Dados Pessoais, mas infelizmente n&atilde;o podemos garantir total seguran&ccedil;a</strong>. Entradas e usos n&atilde;o autorizados de terceiros com informa&ccedil;&otilde;es suas, falhas de <em>hardware</em> ou <em>software</em> que n&atilde;o estejam sob controle da Empresa e outros fatores externos podem comprometer a seguran&ccedil;a dos seus Dados Pessoais. Por isso, sua atua&ccedil;&atilde;o &eacute; fundamental para a manuten&ccedil;&atilde;o de um ambiente seguro para todos. Caso identifique ou tome conhecimento de qualquer fator que comprometa a seguran&ccedil;a dos seus dados com a Empresa, por favor entre em contato conosco por meio das informa&ccedil;&otilde;es de contato indicadas abaixo.</p>

<p className="p4"><strong>7. COMO FALAR SOBRE DADOS PESSOAIS COM A GELIUS INDUSTRIA DE M&Oacute;VEIS?</strong></p>
<p className="p4">Se voc&ecirc; acredita que seus Dados Pessoais foram tratados de maneira incompat&iacute;vel com esta Pol&iacute;tica ou com as suas escolhas enquanto Titular dos seus Dados Pessoais, ou, ainda, se voc&ecirc; tiver d&uacute;vidas, coment&aacute;rios ou sugest&otilde;es relacionadas a esta Pol&iacute;tica e &agrave; forma como tratamos seus Dados Pessoais, entre em contato conosco. N&oacute;s temos um <span className="s2"><strong>Encarregado</strong></span> que est&aacute; &agrave; disposi&ccedil;&atilde;o nos seguintes endere&ccedil;os de contato: lgpd@gelius.com.br</p>

<p className="p4"><strong>8. MUDAN&Ccedil;AS NA POL&Iacute;TICA DE PRIVACIDADE</strong></p>
<p className="p4">Como estamos sempre buscando melhorar os nossos servi&ccedil;os e a forma como operamos, esta Pol&iacute;tica de Privacidade pode passar por atualiza&ccedil;&otilde;es para refletir as melhorias realizadas. Assim nos reservamos no direito de alterar essa pol&iacute;tica sempre que necess&aacute;rio, visando garantir a voc&ecirc; mais seguran&ccedil;a e conveni&ecirc;ncia e melhorar cada vez mais sua experi&ecirc;ncia. Desta forma, recomendamos a visita peri&oacute;dica desta p&aacute;gina para que voc&ecirc; tenha conhecimento sobre as modifica&ccedil;&otilde;es efetivadas.</p>

<p className="p4"><strong>9. LEI APLIC&Aacute;VEL</strong></p>
<p className="p4">Este documento &eacute; regido e deve ser interpretado de acordo com as leis da Rep&uacute;blica Federativa do Brasil, ficando eleito o Foro da Comarca de Mirassol, S&atilde;o Paulo, como o competente para dirimir quaisquer quest&otilde;es porventura oriundas do presente termo, com expressa ren&uacute;ncia a qualquer outro, por mais privilegiado que seja.</p>

<p className="p11"><strong>DATA DA &Uacute;LTIMA ATUALIZA&Ccedil;&Atilde;O: </strong>outubro de 2021.</p>
      </div>
      <Footer />
    </Container>
  )
}
