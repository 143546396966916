import styled from 'styled-components'
import { colors } from '~/config'

export const Container = styled.div`
  width: 100%;
  max-width: 1360px;
  margin: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  flex-direction: row;
  > a > img {
    height: 70px;
    object-fit: contain;
  }
  > .menu-button {
    width: 50px;
    height: 50px;
    color: ${colors.secondary};
    font-size: 20px;
    text-align: right;
    line-height: 50px;
    cursor: pointer;
  }
`
