import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  width: 100%;
  padding: 0 15px;
  max-width: 1360px;
  margin: auto;
  > .BrainhubCarousel__dots > li > button {
    background: transparent!important;
    padding: 15px 5px;

  }
  > .banners {
    display: flex;
  }
`

export const BannerImage = styled.img`
  width: 100%;
  object-fit: contain;
  background-color: ${colors.lightGray};
  border: none;
`
