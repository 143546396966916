import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Container } from './styles'

export const Loading = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Container>
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </Container>
      </motion.div>
    </AnimatePresence>
  )
}
