import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  max-width: 1330px;
  > .swiper-container {
      width: calc(100vw - 60px);
      max-width: 1330px;
    > .swiper-button-prev {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background: ${colors.white};
      position: absolute;
      top: 50%;
      margin-top: -15px;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
      -moz-box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
      box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
      &::after {
        content: '<';
        color: ${colors.black}
      }
    }
    > .swiper-button-next {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background: ${colors.white};
      position: absolute;
      top: 50%;
      margin-top: -15px;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      -webkit-box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
      -moz-box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
      box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
      &::after {
        content: '>';
        color: ${colors.black}
      }
    }
    > .swiper-button-disabled {
      opacity: 0.8;
    }
    > .swiper-wrapper {
      > .swiper-slide {
        width: 320px!important;
      }
    } 
  }
`
