import React, { useEffect, useState } from 'react'
import {
  Header,
  SocialBar,
  Categories,
  Title,
  Wrapper,
  Footer
} from '~/components'
import { Container } from './styles'
import ListProducts from './ListProducts'
import { useParams } from 'react-router-dom'
import { categoryService, productService } from '~/http/service'
import { useLoading } from '~/context/Loading'

export const Products = () => {
  const { category_id } = useParams()
  const { setLoading } = useLoading()

  const [category, setCategory] = useState()
  const [products, setProducts] = useState([])

  useEffect(() => {
    getCategory()
    getProducts(category_id || 1)
  }, [category_id])

  const getProducts = async (category_id) => {
    setLoading(true)
    const response = await productService.getProductsByCategory(category_id)
    const products = response.data.texto
    setProducts(products)
    setLoading(false)
  }

  const getCategory = async () => {
    setLoading(true)
    const response = await categoryService.getCategories()
    const categories = response.data.texto
    setCategory(await categories.filter(category => category.id === category_id)[0])
    setLoading(false)
  }

  return (
    <Container>
      <SocialBar />
      <Header />

      <Wrapper>
        <Title>
          CONHEÇA TODAS <b>AS CATEGORIAS</b>
        </Title>
        <Categories />
        <Title><b>{category?.nome}</b></Title>
        <ListProducts products={products} />
      </Wrapper>
      <Footer/>
    </Container>
  )
}
