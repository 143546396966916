import React, { useLayoutEffect, useState } from 'react'
import Category from './Category'
import { Container } from './styles'
import { Swiper, Navigation } from 'swiper'
import 'swiper/swiper.scss'
import { categoryService } from '~/http/service'
import { useLoading } from '~/context/Loading'

Swiper.use([Navigation])

export const Categories = () => {
  const [categories, setCategories] = useState([])

  const { setLoading } = useLoading()

  useLayoutEffect(() => {
    getCategories()
    const mySwiper = new Swiper('.swiper-container', {
      speed: 400,
      spaceBetween: 15,
      observer: true,
      observeParents: true,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  }, [])

  const getCategories = async () => {
    setLoading(true)
    const response = await categoryService.getCategories()
    setCategories(response.data.texto)
    setLoading(false)
  }

  return (
    <Container>
      <div className="swiper-container">
        <div className="swiper-wrapper">
        {categories.map((category, index) => {
          return category.kids === 'SIM' && (
          <div className="swiper-slide" key={index}>
            <Category
              id={category.id}
              image={category.imagem}
              title={category.nome}
            />
          </div>
          )
        })}

        </div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </Container>
  )
}
