import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  width: 100%;
  height: 95px;
  border-radius: 6px;
  background: ${colors.lightGray};
  position: relative;
  margin: 30px 0;
  > img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
    opacity: 0.16;
  }
  > .content {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }
  @media(min-width: ${breakpoints.lg}) {
    height: 180px;
  }
`
