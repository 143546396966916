import React from 'react'
import { Product, ProductEmpty } from '~/components'
import { Container } from './styles'

const ListProducts = ({ products }) => {
  return (
    <Container>
      {products.map(product => {
        return (
          <Product key={product} novelty={product.lancamento} title={product.nome} image={product.imagem} id={product.id} />
        )
      })}
      <ProductEmpty className="fake"/>
    </Container>
  )
}

export default ListProducts
