import React from 'react'
import { constants } from '~/config'
import { Container } from './styles'

export const Product = ({ id, image, title, novelty }) => {
  return (
    <Container to={`/produto/${id}`}>
      <div className="img-wrapper">
        <img src={`${constants.images}produto/grande/${image}`} alt=""/>
        {novelty === 'S' && <div className="novelty">Lançamento</div>}
      </div>
      <div className="info">
        <div className="title">{title}</div>
        <div className="call">Clique e conheça</div>
      </div>
    </Container>
  )
}

export const ProductEmpty = () => {
  return (
    <Container/>
  )
}
