import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  width: 100%;
  height: 50px;
  background: ${colors.secondary};
  padding: 0 15px;
  > .wrapper {
    width: 100%;
    max-width: 1360px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    > .icons {
      display: flex;
      flex-direction: row;
      > .icon {
        width: auto;
        height: auto;
        border-radius: 3px;
        color: ${colors.secondaryLight};
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 5px;
        > .label {
          font-size: 13px;
          margin-left: 8px;
          margin-right: 15px;
          display: none;
          @media(min-width: ${breakpoints.lg}) {
            display: inline;
          }
        }
      }
    }
  }
`
