import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 15px;
  background: ${colors.secondary};
  margin-top: 100px;
  > .content {
    width: 100%;
    height: auto;
    display: flex;
    max-width: 1360px;
    margin: auto;
    justify-content: center;
    flex-direction: column;
    > .img {
      width: 100%;
      > img {
        width: 100%;
        object-fit: contain;
        margin: 20px auto;
        max-width: 300px;
        display: block;
      } 
    }
    > .address {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      > .text {
        padding-left: 15px;
        flex: 1;
      }
      @media(min-width: ${breakpoints.sm}) {
        width: 50%;
      }
    }
    > .contact {
      margin-bottom: 20px;
      > .item {
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        > .icon {
          line-height: 15px;
        }
        > .text {
          padding-left: 15px;
        } 
      }
      @media(min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.lg}) {
        width: 50%;
        > .item {
          justify-content: flex-end;
        }
      }
      @media(min-width: ${breakpoints.lg}) {
        > .item {
          justify-content: flex-start;
        }
      }
    }  
    > .social {
      > .item {
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        > .icon {
          line-height: 15px;
        }
        > .text {
          padding-left: 15px;
          flex: 1;
        } 
      }
      @media(min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.lg}) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
    @media(min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.lg}) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media(min-width: ${breakpoints.lg}) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      > .img {
        width: auto;
      }
      > .address {
        padding: 0 20px;
        width: auto;
        margin-bottom: 0;
      }
      > .contact {
        margin-bottom: 0;
        padding: 0 20px;
      }
      > .social {
        margin-bottom: 0;
        padding-left: 20px;
      }
    }
  }
  > .rights {
    width: 100%;
    height: auto;
    text-align: center;
    line-height: 60px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 15px;
  }
`
