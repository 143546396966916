import styled from 'styled-components'
import { colors } from '~/config'

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  margin-top: 50px;
  > h1 {
    font-size: 28px;
    color: ${colors.black};
    font-weight: 400;
  }
`
