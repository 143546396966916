export const colors = {
  primary: '#FF0292',
  secondary: '#011F37',
  secondaryLight: '#8F9CA7',
  white: '#FFFFFF',
  lightGray: '#F4F3F1',
  gray: '#878787',
  drakGray: '#373637',
  black: '#011F37'
}
