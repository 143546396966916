import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  > img {
    width: 90%;
    height: 230px;
    border-radius: 33px;
    /* background: ${colors.lightGray}; */
    margin: 30px auto;
    display: block;
    object-fit: contain;
  }
  > form {
    display: block;
    > input {
      border: 1px solid ${colors.lightGray};
      background: #fff;
      width: 100%;
      height: 50px;
      font-size: 14px;
      color: ${colors.gray};
      padding: 0 15px;
      margin-bottom: 15px;
      border-radius: 3px;
      &:focus {
        background: ${colors.lightGray};
      }
    }
    > textarea {
      border: 1px solid ${colors.lightGray};
      background: #fff;
      width: 100%;
      font-size: 14px;
      color: ${colors.gray};
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 3px;
      resize: none;
      &:focus {
        background: ${colors.lightGray};
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    > img {
      width: 30%;
      height: 426px;
      margin: 0 20px 0 0;
    }
    > form {
      > .title {
        margin: 0 0 20px 0;
        padding: 0;
      }
      > button {
        float: left;
      }
    }
  }
`
