import React, { useState, useEffect, useRef } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Container, KidsButton } from './styles'
import { images } from '~/assets'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Representative, WorkWithUs, Access } from '~/components'
import LoadingProvider from '~/context/Loading'

const Menu = () => {
  const SwalWithContent = withReactContent(Swal)

  const [showMenu, setShowMenu] = useState(false)
  const backgroundRef = useRef(null)

  useEffect(() => {
    window.onclick = (event) => {
      event.target === backgroundRef.current && setShowMenu(false)
    }
  }, [])

  const handleRepresentative = () => {
    SwalWithContent.fire({
      title: 'Representantes',
      html: <LoadingProvider><Representative /></LoadingProvider>,
      showConfirmButton: false
    })
  }

  const handleWorkWithUs = () => {
    SwalWithContent.fire({
      title: 'Trabalhe conosco!',
      html: <LoadingProvider><WorkWithUs /></LoadingProvider>,
      showConfirmButton: false
    })
  }

  const handleAccess = () => {
    SwalWithContent.fire({
      title: 'Links úteis',
      html: <Access />,
      showConfirmButton: false
    })
  }

  return (
    <Container>
      <AnimatePresence>
        {showMenu && (
          <div ref={backgroundRef} className="mobile-darker-background">
            <motion.nav
              key="content"
              initial="closed"
              animate="open"
              exit="closed"
              variants={{
                open: { opacity: 1, width: 'calc(100% - 80px)' },
                closed: { opacity: 0, width: 0 }
              }}
            >
              <Link to="/" onClick={() => setShowMenu(false)}>
                <img src={images.logoKids} />
              </Link>
              <ul>
                <li>
                  <HashLink onClick={() => setShowMenu(false)} to="/#catalogos">CATÁLOGOS</HashLink>
                </li>
                <li>
                  <Link onClick={() => setShowMenu(false)} to="/historia">HISTÓRIA</Link>
                </li>
                <li>
                  <a onClick={handleRepresentative}>REPRESENTANTES</a>
                </li>
                <li>
                  <HashLink onClick={() => setShowMenu(false)} to="/#contato">CONTATO</HashLink>
                </li>
                <li>
                  <a onClick={handleWorkWithUs}>TRABALHE CONOSCO</a>
                </li>
                <li>
                  <a onClick={handleAccess}>ACESSOS</a>
                </li>
                <li>
                  <a href="https://gelius.com.br"><img src={images.logo} alt=""/></a>
                </li>
              </ul>
            </motion.nav>
          </div>
        )}
      </AnimatePresence>
      <nav className="menu-desktop">
        <ul>
          <li>
            <HashLink to="/#catalogos">CATÁLOGOS</HashLink>
          </li>
          <li>
            <Link to="/historia">HISTÓRIA</Link>
          </li>
          <li>
            <a onClick={handleRepresentative}>REPRESENTANTES</a>
          </li>
          <li>
            <HashLink to="/#contato">CONTATO</HashLink>
          </li>
          <li>
            <a onClick={handleWorkWithUs}>TRABALHE CONOSCO</a>
          </li>
          <li>
            <a onClick={handleAccess}>ACESSOS</a>
          </li>
          <li>
            <a href="https://gelius.com.br"><img src={images.logo} alt=""/></a>
          </li>
        </ul>
      </nav>
      <div className="hamburger-button" onClick={() => setShowMenu(!showMenu)}>
        <GiHamburgerMenu />
      </div>
    </Container>
  )
}

export default Menu
