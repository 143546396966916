import React from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import { Home, Product, Products, History, Terms } from '~/views'

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/produtos/:category_id" component={Products} />
        <Route exact path="/produto/:product_id" component={Product} />
        <Route exact path="/historia" component={History} />
        <Route exact path="/termos" component={Terms} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
