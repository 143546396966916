import styled from 'styled-components'

export const Container = styled.div`
  > a {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px;
    margin-bottom: 15px;
    color: #333;
  }
`
