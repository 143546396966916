import styled from 'styled-components'
import { colors, breakpoints } from '~/config'
import { Link } from 'react-router-dom'

export const Container = styled(Link)`
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  display: table;
  > .img-wrapper {
    position: relative;
    overflow: hidden;
    > .novelty {
      width: 130px;
      height: 30px;
      background: ${colors.primary};
      position: absolute;
      top: 19px;
      left: -29px;
      text-align: center;
      font-size: 11px;
      font-weight: 600;
      line-height: 30px;
      color: #fff;
      text-transform: uppercase;
      transform: rotate(-45deg);
    }
    > img {
      width: 100%;
      height: 260px;
      background: ${colors.lightGray};
    }
  }
  > .info {
    > .title {
      font-size: 16px;
      font-weight: 700;
      color: ${colors.black}
    }
    > .call {
      font-size: 13px;
      color: ${colors.black};
      font-weight: 300;
    }
  }

  @media(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    width: calc((100% - 15px) / 2);
    margin-bottom: 40px;
  }

  @media(min-width: ${breakpoints.lg}) {
    width: calc((100% - 30px) / 3);
    margin-bottom: 40px;
  }
`
