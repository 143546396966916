import React from 'react'

import { Container } from './styles'

export const Catalog = ({ split, title, description, url, image, target, rel }) => {
  return (
    <Container href={url} target={target || ''} rel={rel || ''} split={split}>
      <img src={image} alt=""/>
      <div className="button">clique e conheça</div>
      <div className="info">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </Container>
  )
}
