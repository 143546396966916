import React from 'react'

import { Container } from './styles'

export const Title = ({ children, className, id, style }) => {
  return (
    <Container id={id} className={className} style={style}>
      <h1>{children}</h1>
    </Container>
  )
}
