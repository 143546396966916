import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  > .content {
    width: 100%;
    height: auto;
    display: table;
    padding: 15px;
    max-width: 1360px;
    margin: auto;
    table {
      border: 1px solid #eee;
      td {
      border: 1px solid #eee;
        padding: 5px 15px;
      }
    }
  }
`
