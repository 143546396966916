import React, { useState } from 'react'
import { Button, Loading } from '~/components'
import { Container } from './styles'
import { BiPaperclip } from 'react-icons/bi'
import axios from 'axios'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { useLoading } from '~/context/Loading'

export const WorkWithUs = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [file, setFile] = useState('')

  const { setLoading, loading } = useLoading()

  const handleFormSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const payload = new FormData()
    payload.append('email', email)
    payload.append('name', name)
    payload.append('curriculo', file)

    const response = await axios.post('https://gelius.com.br/site/app/ajax/trabalhe-conosco.php', payload)
    setLoading(false)
    if (response.data !== 'success') {
      return toast.warn(response.data, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    toast.success('Formulário enviado com sucesso!', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
    return Swal.close()
  }

  return (
    <Container onSubmit={handleFormSubmit}>
      {loading && <Loading/>}

      <p>Envie-nos seu currículo para analisarmos</p>

      <div className="form-group">
        <label>Seu nome:</label>
        <input type="text" value={name} onChange={(event) => setName(event.target.value)} required />
      </div>

      <div className="form-group">
        <label>Seu e-mail:</label>
        <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
      </div>

      <div className="form-group">
        <label htmlFor="">Currículo:</label>

        <label htmlFor="curriculo">
          <div className="file-button">
            <BiPaperclip/> Selecionar arquivo
          </div>
        </label>
        <input
          type="file"
          className="file-input"
          name="curriculo"
          id="curriculo"
          onChange={(event) => setFile(event.target.files[0])}
        />
      </div>

      <Button title="Enviar" type="submit" style={{ margin: 'auto' }} />
    </Container>
  )
}
