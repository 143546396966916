import React from 'react'
import Routes from '~/routes'
import { GlobalStyles } from '~/config'
import { Loading } from '~/components'
import { useLoading } from '~/context/Loading'

function App () {
  const { loading } = useLoading()

  return (
    <>
      <GlobalStyles/>
      <Routes/>
      {loading && <Loading/>}
    </>
  )
}

export default App
