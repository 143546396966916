import React, { useEffect, useState } from 'react'
import { Container, BannerImage } from './styles'
import { bannerService } from '~/http/service'
import { constants } from '~/config'
import Carousel, { Dots } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { useLoading } from '~/context/Loading'

export const Banner = () => {
  const [banners, setBanners] = useState([])
  const [currentBanner, setCurrentBanner] = useState(0)

  const { setLoading } = useLoading()

  useEffect(() => {
    getBanners()
  }, [])

  const getBanners = async () => {
    setLoading(true)
    const response = await bannerService.getBanners()
    const banners = response.data.texto
    setBanners(banners.filter(banner => banner.kids === 'SIM'))
    setLoading(false)
  }

  return (
    <Container>
      <Carousel className="banners"
        value={currentBanner}
        onChange={value => setCurrentBanner(value)}
        autoPlay={5000}
        infinite
      >
        {banners.map((banner) => (
          <a href={banner.link} key={banner.id}>
            <BannerImage src={`${constants.images}banner/${banner.imagem}`} />
          </a>
        ))}
      </Carousel>
      <Dots
        value={currentBanner}
        onChange={value => setCurrentBanner(value)}
        number={banners?.length}
      />
    </Container>
  )
}
