import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  > .results {
    > .representative {
      border: 1px solid #eee;
      margin-bottom: 30px;
      > .row {
        width: 100%;
        padding: 15px;
        text-align: left;
        font-size: 13px;
        background: #fff;
        color: #333;
        &:nth-child(2n+1) {
          background: #f4f4f4;
        }
      }
    }
  }
`
