import styled from 'styled-components'
import { colors } from '~/config'

export const Container = styled.button`
  width: auto;
  height: 50px;
  cursor: pointer;
  position: relative;
  display: table;
  text-align: left;
  background: transparent;
  > .circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: ${colors.black};
    color: ${colors.white};
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: -1px;
    position: absolute;
    transition: all 0.3s;
    padding-left: 14px;
    z-index: 1;
  }
  > .content {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.black};
    height: 50px;
    line-height: 50px;
    margin-left: 50px;
    padding: 0 20px 0 10px;
    transition: all 0.3s;
    z-index: 2;
  }
  &:hover {
    > .circle {
      width: 100%;
    }
    > .content {
      color: ${colors.white};
    }
  }
`
