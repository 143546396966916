import React from 'react'
import { Container } from './styles'
import { Link } from 'react-router-dom'

export const PrivacyFooter = () => {
  const [isAccepted, setIsAccepted] = React.useState(true)

  const onAccept = () => {
    localStorage.setItem('terms', 'accepted')
    setIsAccepted(true)
  }

  const onDecline = () => {
    window.location.href = 'https://www.google.com/'
  }

  React.useEffect(() => {
    const terms = localStorage.getItem('terms')
    terms === 'accepted' ? setIsAccepted(true) : setIsAccepted(false)
  }, [])

  return (
    <>
      {!isAccepted && (
        <Container>
          <p>
            Utilizamos cookies para oferecer melhor experiência, melhorar o
            desempenho, analisar como você interage em nosso site e personalizar

            conteúdo (<Link to="/termos">clique aqui e saiba mais</Link>)
          </p>
          <div>
            <button className="decline" onClick={onDecline}>
              Recusar
            </button>
            <button onClick={onAccept}>Aceitar</button>
          </div>
        </Container>
      )}
    </>
  )
}
