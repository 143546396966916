import styled from 'styled-components'
import { colors, breakpoints } from '~/config'
import { images } from '~/assets'

export const Container = styled.div`
  > .content {
    width: 100%;
    padding: 0 15px;
    max-width: 1360px;
    margin: auto;
    height: auto;
    display: table;
    > .title {
      display: block;
    }
    > .preview {
      width: 100%;
      height: auto;
      display: table;
      > .download {
        width: auto;
        height: 50px;
        padding: 0 30px;
        background: ${colors.secondary};
        color: #fff;
        font-weight: 600;
        text-align: center;
        line-height: 50px;
        font-size: 13px;
        float: right;
        cursor: pointer;
      }
      > img {
        width: 100%;
        max-height: 400px;
        margin: 15px 0;
        object-fit: contain;
      }
      > .thumbs-container {
        max-width: 1366px;
        width: calc(100vw - 30px);
        overflow: auto;
        padding: 10px 0;
        > .swiper-container {
          > .swiper-button-prev {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background: ${colors.white};
            position: absolute;
            top: 50%;
            margin-top: -15px;
            z-index: 9;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
            -moz-box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
            box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
            &::after {
              content: '<';
              color: ${colors.black}
            }
          }
          > .swiper-button-next {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background: ${colors.white};
            position: absolute;
            top: 50%;
            margin-top: -15px;
            z-index: 9;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            -webkit-box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
            -moz-box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
            box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
            &::after {
              content: '>';
              color: ${colors.black}
            }
          }
          > .swiper-button-disabled {
            opacity: 0.8;
          }  
          > .swiper-wrapper > .swiper-slide {
            width: 80px;
            > img {
              width: 80px;
              height: 80px;
              background: ${colors.lightGray};
              display: block;
              margin-right: 15px;
              object-fit: cover;
            }
          } 
        }
        
      }
    }
    > .infos {
      width: 100%;
      height: auto;
      display: table;
      > .title {
        display: none;
        margin-top: 56px;
        margin-bottom: 0;
        > h1 {
          margin: 0;
        }
      }
      > .colors-wrapper {
        width: 100%;
        height: auto;
        display: table;
        padding: 15px 0;
        border-top: 1px solid ${colors.lightGray};
        border-bottom: 1px solid ${colors.lightGray};
        > h2 {
          font-size: 15px;
          color: ${colors.black};
          font-weight: 600;
          margin: 0;
          margin-bottom: 5px;
        }
        > .colors-container {
          max-width: 1366px;
          width: calc(100vw - 30px);
          overflow: auto;
          padding: 10px 0;
            > div {
              > .swiper-button-prev {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                background: ${colors.white};
                position: absolute;
                top: 50%;
                margin-top: -15px;
                z-index: 9;
                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
                -moz-box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
                box-shadow: -10px 0px 6px 0px rgba(0,0,0,0.08);
                &::after {
                  content: '<';
                  color: ${colors.black}
                }
              }
              > .swiper-button-next {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                background: ${colors.white};
                position: absolute;
                top: 50%;
                margin-top: -15px;
                z-index: 9;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 0;
                -webkit-box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
                -moz-box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
                box-shadow: 10px 0px 6px 0px rgba(0,0,0,0.08);
                &::after {
                  content: '>';
                  color: ${colors.black}
                }
              }
              > .swiper-button-disabled {
                opacity: 0.8;
              }  
              > div > div {
                width: 120px;
                  > img {
                    width: 120px;
                    background: ${colors.lightGray};
                    margin-right: 15px;
                  }
              }
            }
          
        }
      }
      > .description {
        font-size: 15px;
        color: ${colors.gray};
        margin: 15px 0;
      }
      > .details {
        padding-top: 15px;
        > div > div > h2 {
          font-size: 15px;
          color: ${colors.black};
          font-weight: 600;
          margin-bottom: 5px;
        }

        > .content {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          > hr {
            border-left: 1px solid ${colors.lightGray};
            width: 1px;
            height: auto;
          }
          > div {
            max-width: 48%;
            display: table;
            > ul {
              padding: 0;
              margin: 0;
              > li {
                list-style: square inside url(${images.square});
                font-size: 13px;
                color: ${colors.gray};
                margin: 10px 0;
              }
            }
          }
        }
      }
      > .video {
        margin: 15px 0;
        border-top: 1px solid ${colors.lightGray};
      }
    }
  }
  @media (min-width: ${breakpoints.md}) {
    > .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > .title {
        display: none;
      }
      > .preview {
        width: calc(50% - 30px);
        display: inline-block;
        > .thumbs-container {
          width: 100%;
        }
      }
      > .infos {
        width: 50%;
        display: flex;
        flex-direction: column;
        > .title {
          display: block;
        }
        > .description {
          order: 1;
        }
        > .details {
          order: 4;
        }
        > .video {
          order: 3;
        }
        > .colors-wrapper {
          order: 2;
          display: inline-block;
          > .colors-container {
            width: 100%;
            overflow: auto;
            padding: 10px 0;
          }
        }
      }
    }
  }
`
