import React from 'react'
import { images } from '~/assets'
import { Container } from './styles'
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
  FaFax,
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare
} from 'react-icons/fa'
import { PrivacyFooter } from '~/components'

export const Footer = () => {
  return (
    <>
      <PrivacyFooter />

      <Container>
        <div className="content">
          <div className="img">
            <img src={images.logo} alt="" />
          </div>

          <div className="address">
            <div className="icon">
              <FaMapMarkerAlt />
            </div>
            <div className="text">
              Avenida Modesto José Moreira Júnior, 4545
              <br />
              Bairro Portal, Caixa Postal 138
              <br />
              CEP 15133-272
              <br />
              Mirassol - SP, Brasil
            </div>
          </div>

          <div className="contact">
            <div className="item">
              <div className="icon">
                <FaPhone />
              </div>
              <div className="text">55 (17) 3253-9200</div>
            </div>
            <div className="item">
              <div className="icon">
                <FaFax />
              </div>
              <div className="text">
                <b>FAX:</b> 55 (17) 3253-9208
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <FaEnvelope />
              </div>
              <div className="text">geliusmoveis@gelius.com.br</div>
            </div>
          </div>

          <div className="social">
            <a
              href="https://www.facebook.com/geliusmoveis/"
              rel="noreferrer"
              target="_blank"
              className="item"
            >
              <div className="icon">
                <FaFacebookSquare />
              </div>
              <div className="text">Facebook</div>
            </a>
            <a
              href="https://www.instagram.com/geliusmoveis/?hl=pt-br"
              rel="noreferrer"
              target="_blank"
              className="item"
            >
              <div className="icon">
                <FaInstagramSquare />
              </div>
              <div className="text">Instagram</div>
            </a>
            <a
              href="https://www.youtube.com/channel/UCdVMaViYEF5Fd6wWzOZDNmQ?view_as=subscriber"
              rel="noreferrer"
              target="_blank"
              className="item"
            >
              <div className="icon">
                <FaYoutubeSquare />
              </div>
              <div className="text">Youtube</div>
            </a>
          </div>
        </div>
        <div className="rights">© Copyright 2021 - Gelius Móveis</div>
      </Container>
    </>
  )
}
