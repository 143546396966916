import { createGlobalStyle } from 'styled-components'
import { colors } from '~/config'
import { images } from '~/assets'

export const GlobalStyles = createGlobalStyle`
  * { 
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    font-family: "Poppins", sans-serif;
  }
  
  html, body {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    background: url(${images.babyBackground}) repeat;
  }

  .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 6px;
  }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .embed-responsive-16by9 {
    padding-bottom: 56.25%;
  }
  .embed-responsive-4by3 {
    padding-bottom: 75%;
  }
  #svg-map path { fill:#1c69c5 }
  #svg-map text { fill:#fff; font:12px Arial-BoldMT, sans-serif; cursor:pointer }
  #svg-map a{ text-decoration:none }
  #svg-map a:hover { cursor:pointer; text-decoration:none }
  #svg-map a:hover path{ fill:rgb(239, 10, 129) !important }
  #svg-map a:hover .circle { fill: #1c69c5 !important; cursor:pointer; border: 4px solid black; }
`
