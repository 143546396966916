import http from '~/http'

export const productService = {
  getProducts: () => {
    return http({
      url: 'produtos'
    })
  },
  getProductsByCategory: (category_id) => {
    return http({
      url: `produto_categoria/${category_id}`
    })
  },
  getProduct: (product_id) => {
    return http({
      url: `produto/${product_id}`
    })
  },
  getProductFeatures: (product_id) => {
    return http({
      url: `produto_caracteristica/${product_id}`
    })
  },
  getProductDetails: (product_id) => {
    return http({
      url: `produto_detalhe/${product_id}`
    })
  },
  getProductColors: (product_id) => {
    return http({
      url: `produto_cor/${product_id}`
    })
  },
  getProductReversible: (product_color_id) => {
    return http({
      url: `produto_reversivel/${product_color_id}`
    })
  }
}
