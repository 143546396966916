import styled from 'styled-components'
import { colors } from '~/config'

export const Container = styled.form`
  text-align: left;
  > p {
    text-align: center;
    font-size: 15px;
    margin-bottom: 30px;
  }
  > .form-group {
    > label {
      font-size: 14px;
      margin-bottom: 15px;
      color: ${colors.drakGray};
    }
    > input {
      border: 1px solid ${colors.lightGray};
      background: #fff;
      width: 100%;
      height: 50px;
      font-size: 14px;
      color: ${colors.gray};
      padding: 0 15px;
      margin-bottom: 15px;
      border-radius: 3px;
      &:focus {
        background: ${colors.lightGray};
      }
    }
    .file-button {
      border-radius: 2px;
      background: ${colors.primary};
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      padding: 15px;
      cursor: pointer;
      margin-bottom: 30px;
    }

    .file-button i {
      margin-right: 10px;
    }

    .file-input {
      display: none;
    }
  }
`
