import React from 'react'

import { Container } from './styles'

export const Button = ({ title, onClick, type, style }) => {
  return (
    <Container style={style} type={type || 'button'} onClick={onClick || null}>
      <div className="circle">...</div>
      <div className="content">{title}</div>
    </Container>
  )
}
