import React from 'react'
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlinePhone,
  AiOutlineMail
} from 'react-icons/ai'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Container } from './styles'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const SocialBar = () => {
  const handleCopyToClipboard = (itemName) => {
    toast.dark(`${itemName} foi copiado para sua área de transferência!`, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }

  return (
    <Container>
      <ToastContainer />
      <div className="wrapper">
        <div className="icons">
          <CopyToClipboard
            text="+55 (17) 3253-9200"
            onCopy={() => handleCopyToClipboard('Telefone')}
          >
            <div className="icon">
              <AiOutlinePhone />
              <div className="label">+55 (17) 3253-9200</div>
            </div>
          </CopyToClipboard>
          <CopyToClipboard
            text="geliusmoveis@gelius.com.br"
            onCopy={() => handleCopyToClipboard('Email')}
            className="icon"
          >
            <div className="icon">
              <AiOutlineMail />
              <div className="label">geliusmoveis@gelius.com.br</div>
            </div>
          </CopyToClipboard>
        </div>
        <div className="icons">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/geliusmoveis/"
            className="icon"
          >
            <AiOutlineFacebook />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/geliusmoveis/?hl=pt-br"
            className="icon"
          >
            <AiOutlineInstagram />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCdVMaViYEF5Fd6wWzOZDNmQ?view_as=subscriber"
            className="icon"
          >
            <AiOutlineYoutube />
          </a>
        </div>
      </div>
    </Container>
  )
}
