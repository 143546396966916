import React from 'react'
import { images } from '~/assets'
import { Container } from './styles'
import Menu from './Menu'
import { Link } from 'react-router-dom'
export const Header = () => {
  return (
    <Container>
      <Link to="/">
        <img src={images.logoKids} alt=""/>
      </Link>
      <Menu />
    </Container>
  )
}
