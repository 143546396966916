import React from 'react'
import { Button } from '~/components'
import { images } from '~/assets'
import { Container } from './styles'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Maps from './Maps'

export const Localization = () => {
  const SwalWithContent = withReactContent(Swal)

  const handleLocalization = () => {
    SwalWithContent.fire({
      title: 'Nossa localização',
      html: <Maps />
    })
  }

  return (
    <Container>
      <img src={images.maps} alt="Imagem de mapa vetorizado"/>
      <div className="content">
        <Button title="SAIBA NOSSA LOCALIZAÇÃO" onClick={handleLocalization}/>
      </div>
    </Container>
  )
}
