import React from 'react'
import { Container } from './styles'

export const Access = () => {
  return (
    <Container>
      <a href="https://webmail.gelius.com.br/" target="__blank">Webmail</a>
      <a href="http://gelius.com.br/forca-vendas" target="__blank">Força de vendas</a>
    </Container>
  )
}
