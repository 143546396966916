import styled from 'styled-components'
import { colors, breakpoints } from '~/config'
import { Link } from 'react-router-dom'

export const KidsButton = styled(Link)`
  background: #1BACEC;
  color: #fff!important;
  font-size: 13px!important;
  height: 50px;
  border-radius: 50px;
  padding: 0 15px;
  line-height: 50px!important;
  display: block!important;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
  @media(min-width: ${breakpoints.lg}) {
    margin: 0;
  }
`

export const Container = styled.div`
  > .mobile-darker-background {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    > nav {
      padding: 15px;
      width: calc(100% - 80px);
      max-width: 400px;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      > a {
        > img {
          width: 175px;
          height: 75px;
          object-fit: contain;
          margin: auto;
          display: block;
        }
      }
      > ul {
        margin-top: 50px;
        padding-inline-start: 0;
        > li {
          > a {
            width: 100%;
            display: block;
            line-height: 40px;
            font-size: 13px;
            color: ${colors.black};
            border-bottom: 1px solid ${colors.lightGray};
            text-align: center;
            > img {
              height: 70px;
              object-fit: contain;
           }
          }
        }
      }
    }
  }
  > .hamburger-button {
    background: transparent;
    width: 50px;
    height: 50px;
    cursor: pointer;
    color: ${colors.secondary};
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
  }

  > nav.menu-desktop {
    display: none;
    > ul {
      display: flex;
      align-items: center;
      > li {
        float: left;
        margin-left: 20px;
        > a {
          width: 100%;
          display: block;
          line-height: 40px;
          font-size: 13px;
          color: ${colors.black};
          > img {
            height: 70px;
            object-fit: contain;
          }
        }
      }
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    > .hamburger-button {
      display: none;
    }
    > nav.menu-desktop {
      display: table;
    }
  }
`
