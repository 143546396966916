import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Header, SocialBar, Title, Footer } from '~/components'
import { Container } from './styles'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useParams } from 'react-router-dom'
import { constants } from '~/config'
import { productService } from '~/http/service'
import { isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { useLoading } from '~/context/Loading'
import { Swiper, Navigation } from 'swiper'
import 'swiper/swiper.scss'
import axios from 'axios'

export const Product = () => {
  Swiper.use([Navigation])
  const [product, setProduct] = useState([])
  const [productFeatures, setProductFeatures] = useState([])
  const [productColors, setProductColors] = useState([])
  const [productSchema, setProductSchema] = useState([])
  const [thumbs, setThumbs] = useState([])
  const [currentThumbs, setCurrentThumbs] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(null)
  const [lightboxVisible, setLightboxVisible] = useState(false)

  const { product_id } = useParams()
  const { setLoading } = useLoading()

  useEffect(() => {
    getProduct(product_id || 1)
  }, [])

  useEffect(() => {
    setCurrentThumbs(thumbs[0])
    setCurrentImageIndex(0)
  }, [thumbs])

  useLayoutEffect(() => {
    const mySwiper = new Swiper('.swiper-container', {
      speed: 400,
      spaceBetween: 15,
      observer: true,
      observeParents: true,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  }, [])

  const getProduct = async (product_id) => {
    setLoading(true)
    const response = await productService.getProduct(product_id || 1)
    const product = response.data.texto
    setProduct(product.produto[0])
    setProductFeatures(product.caracteristicas)
    setProductColors(product.cores)
    setProductSchema(product.esquema_montagem)

    product.cores.forEach((cor) => {
      const thumb = {
        id: cor.id,
        images: [{ image: cor.imagem }]
      }

      product.detalhes.forEach((detalhe) => {
        if (detalhe.id_produto_cor === cor.id) {
          thumb.images.push({ image: detalhe.imagem, name: 'Detalhe' })
        }
      })

      product.reversiveis.forEach((reversivel) => {
        if (reversivel.id_produto_cor === cor.id) {
          thumb.images.push({
            image: reversivel.imagem,
            name: reversivel.nome
          })
        }
      })

      setThumbs((thumbs) => [...thumbs, thumb])
    })
    setLoading(false)
  }

  const handleDownloadImage = (image) => {
    window.open(`https://gelius.com.br/site/baixar.php?img=${image}`)
  }

  const handleDownloadScheme = async (e) => {
    e.preventDefault()
    for (const schema of productSchema) {
      await axios({
        url: `https://gelius.com.br/site/baixar.php?pdf=${schema.pdf}`,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', schema.pdf)
        document.body.appendChild(link)
        link.click()
      })
    }
  }

  const handleSelectColor = (color_id) => {
    setCurrentThumbs(thumbs.find((thumb) => thumb.id === color_id))
    setCurrentImageIndex(0)
  }

  const handleSelectImage = (index) => {
    setCurrentImageIndex(index)
  }

  const getYoutubeEmbed = (videoUrl) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = videoUrl.match(regExp)
    return match && match[7].length === 11
      ? 'https://www.youtube.com/embed/' + match[7]
      : false
  }

  return (
    <Container>
      <SocialBar />
      <Header />
      <div className="content">
        <Title className="title">
          <b>{product.nome}</b>
        </Title>
        <div className="preview">
          <div
            className="download"
            onClick={() => handleDownloadImage(`${!isEmpty(currentThumbs) && currentThumbs.images[currentImageIndex].image}`)}
          >
            BAIXAR IMAGEM
          </div>
          <img
            src={`${constants.images}produto/grande/${!isEmpty(currentThumbs) && currentThumbs.images[currentImageIndex].image}`}
            alt=""
            onClick={() => setLightboxVisible(true)}
          />
          {lightboxVisible && (
          <Lightbox
            mainSrc={`${constants.images}produto/grande/${!isEmpty(currentThumbs) && currentThumbs.images[currentImageIndex].image}`}
            onCloseRequest={() => setLightboxVisible(false)}
            imagePadding={60}
            nextSrc={`${constants.images}produto/grande/${!isEmpty(currentThumbs) && currentThumbs.images[(currentImageIndex + 1) % currentThumbs.images.length].image}`}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            prevSrc={`${constants.images}produto/grande/${!isEmpty(currentThumbs) && currentThumbs.images[(currentImageIndex + currentThumbs.images.length - 1) % currentThumbs.images.length].image}`}
            onMovePrevRequest={() => setCurrentImageIndex(currentImageIndex => (currentImageIndex + currentThumbs.images.length - 1) % currentThumbs.images.length)}
            onMoveNextRequest={() => setCurrentImageIndex(currentImageIndex => (currentImageIndex + 1) % currentThumbs.images.length)}
          />
          )}
          <div className="thumbs-container">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {!isEmpty(currentThumbs) && currentThumbs.images.map((image, index) => {
                  return (
                    <div className="swiper-slide" key={image.id}>
                      <img
                        src={`${constants.images}produto/grande/${image.image}`}
                        alt=""
                        onClick={() => handleSelectImage(index)}
                        key={index}
                        data-tip={image.name}
                      />
                      <ReactTooltip place="bottom" type="dark" effect="float" />
                    </div>
                  )
                })}
              </div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
          {productSchema.length > 0 &&
            (
              <a
                className="download"
                onClick={e => handleDownloadScheme(e)}
                style={{ float: 'left', marginTop: 15 }}
                href="#"
              >
                BAIXAR ESQUEMA DE MONTAGEM
              </a>
            )
          }
        </div>

        <div className="infos">
          <Title className="title">
            <b>{product.nome}</b>
          </Title>
          <div className="colors-wrapper">
            <h2>Cores disponíveis</h2>
            <div className="colors-container">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  {productColors.map((color, index) => {
                    return (
                      <div className="swiper-slide" key={color.id}>
                        <img
                          onClick={() =>
                            handleSelectColor(color.id)
                          }
                          src={`${constants.images}produto/grande/${color.imagem}`}
                          alt={color.nome}
                          data-tip={color.nome}
                        />
                        <ReactTooltip place="bottom" type="dark" effect="float" />
                      </div>
                    )
                  })}
                  </div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </div>
            </div>
          </div>
          <p className="description">{product.fica_dica}</p>
          {product.video && (
            <div className="video embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src={getYoutubeEmbed(product.video)}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
          <div className="details">
            <div className="content">
              <div className="measures">
                <h2>Cores disponíveis</h2>
                <ul>
                  {productColors.map((color, index) => (<li key={index}>{color.nome}</li>))}
                </ul>
                <h2>Medidas</h2>
                <ul>
                  {product.altura && (
                    <li>
                      Altura: {product.altura}
                      <br />
                    </li>
                  )}
                  {product.largura && (
                    <li>
                      Largura: {product.largura}
                      <br />
                    </li>
                  )}
                  {product.comprimento && (
                    <li>
                      Comprimento: {product.comprimento}
                      <br />
                    </li>
                  )}
                  {product.peso && (
                    <li>
                      Peso: {product.peso}
                      <br />
                    </li>
                  )}
                  {product.peso_suportado && (
                    <li>
                      Peso suportado: {product.peso_suportado}
                      <br />
                    </li>
                  )}
                </ul>
              </div>
              <hr />
              <div className="features">
                <h2>Características</h2>

                <ul>
                  {productFeatures.map((feature, index) => (
                    <li key={index}>
                      {feature.nome}
                      <br />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  )
}
