import styled from 'styled-components'
import { breakpoints } from '~/config'

export const Container = styled.a`
  width: 100%;
  height: 260px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .button {
    width: auto;
    height: 50px;
    background: #111;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    padding: 0 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 50px;
  }
  > .info {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 10px;
    > h1 {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }
    > p {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    width: ${(props) => (props.split ? '49%' : '100%')};
    height: 380px;
  }
`
