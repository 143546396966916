import React, { useEffect, useState } from 'react'
import { Catalog } from '~/components'
import { Container } from './styles'
import { catalogService } from '~/http/service'
import { constants } from '~/config'
import { useLoading } from '~/context/Loading'

const Catalogs = () => {
  const [catalogs, setCatalogs] = useState([])
  const { setLoading } = useLoading()

  useEffect(() => {
    getCatalogs()
  }, [])

  const getCatalogs = async () => {
    setLoading(true)
    const response = await catalogService.getCatalogs()
    const catalogs = response.data.texto
    setCatalogs(catalogs.filter(catalog => catalog.kids === 'SIM'))
    setLoading(false)
  }

  return (
    <Container>
      {catalogs?.map((catalog, index) => {
        return (
          <Catalog
            title={catalog.nome}
            description={catalog.descricao}
            url={catalog.url}
            image={constants.images + 'catalogo/' + catalog.imagem}
            key={index}
            split={true}
            target="_blank"
            rel="noreferrer"
          />
        )
      })}
    </Container>
  )
}

export default Catalogs
