import babyBackground from './images/baby-background.png'
import logoKids from './images/logo-kids.png'
import logo from './images/logo.png'
import maps from './images/maps.png'
import g from './images/g.png'
import square from './images/square.png'

export const images = {
  babyBackground,
  logoKids,
  logo,
  maps,
  g,
  square
}
