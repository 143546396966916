import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { representativeService } from '~/http/service'
import Map from './Map'
import { Container } from './styles'
import { Loading } from '~/components'
import { useLoading } from '~/context/Loading'

export const Representative = () => {
  const [uf, setUf] = useState(null)
  const [representatives, setRepresentatives] = useState([])

  const { setLoading, loading } = useLoading()

  const handleSelectState = (uf) => {
    setUf(uf)
    getRepresentativeByUf(uf)
  }

  const getRepresentativeByUf = async (uf) => {
    try {
      setLoading(true)
      const response = await representativeService.getRepresentativeByUf(uf)
      setRepresentatives(response.data.texto)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      {loading && <Loading/>}
      <Map handleSelectState={handleSelectState} />
      {!isEmpty(representatives) && (
        <div className="results">
          <h2>
            Representantes em {uf}
          </h2>
          {representatives.map((representative, index) => {
            return (
              <div className="representative" key={index}>
                {representative.nome && (
                  <div className="row">
                    <b>Nome:</b> {representative.nome}
                  </div>
                )}
                {representative.telefone && (
                  <div className="row">
                    <b>Telefone:</b> {representative.telefone}
                  </div>
                )}
                {representative.celular && (
                  <div className="row">
                    <b>Celular:</b> {representative.celular}
                  </div>
                )}
                {representative.regiao_atua && (
                  <div className="row">
                    <b>Região que atende:</b> {representative.regiao_atua}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </Container>
  )
}
