import React, { useState } from 'react'
import { Button, Title } from '~/components'
import { Container } from './styles'
import axios from 'axios'
import { toast } from 'react-toastify'
import { images } from '~/assets'
import { useLoading } from '~/context/Loading'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const { setLoading } = useLoading()

  const handleSubmit = async (event) => {
    event.preventDefault()

    const payload = new FormData()
    payload.append('name', name)
    payload.append('email', email)
    payload.append('message', message)

    try {
      setLoading(true)
      const response = await axios.post('https://gelius.com.br/site/app/ajax/contato.php', payload)
      if (response.data !== 'success') {
        return toast.warn(response.data, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
      }
      toast.success('Formulário enviado com sucesso!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container id="contato">
      <img
        src={images.g}
        alt=""
      />
      <form onSubmit={(event) => handleSubmit(event)}>
        <Title className="title">
          TEM ALGO <b>A NOS DIZER?</b>
        </Title>
        <input type="text" placeholder="Seu nome" value={name} onChange={event => setName(event.target.value)} />
        <input type="email" placeholder="Seu email" value={email} onChange={event => setEmail(event.target.value)} />
        <textarea placeholder="Oque tem a nos dizer?" value={message} rows={5} onChange={event => setMessage(event.target.value)} />
        <Button
          style={{ margin: 'auto' }}
          title="Enviar mensagem"
          type="submit"
        />
      </form>
    </Container>
  )
}

export default Contact
