import styled from 'styled-components'
import { colors, breakpoints } from '~/config'

export const Container = styled.div`
  > .content {
    width: 100%;
    height: auto;
    display: table;
    padding: 15px;
    max-width: 1360px;
    margin: auto;
    > div {
      display: flex;
      flex-direction: column;
      > p {
        font-size: 15px;
        color: ${colors.gray};
        font-weight: 300;
      }
      > .content {
        > p {
          font-size: 15px;
          color: ${colors.gray};
          font-weight: 300;
        }
      }
      > .img {
        margin: 15px 0;
        > img {
          width: 100%;
          height: 350px;
          border-radius: 9px;
        }
        > .blur {
          width: 80%;
          height: 15px;
          border-radius: 100%;
          background: ${colors.gray};
          opacity: 0.1;
          filter: blur(5px);
          margin: 5px auto;
        }
      }
    }

    @media(min-width: ${breakpoints.md}) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      > .split {
        width: calc(50% - 15px);
      }

      > .history-video {
        > .img {
          order: 1;
          margin-top: 140px;
        }
        > p {
          order: 2;
        }
      } 

      > .exportation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > div {
          width: calc(50% - 15px);
        }
      }
    }
  }
`
