import React from 'react'
import { Header, SocialBar, Banner, Title, Footer } from '~/components'
import { Container } from './styles'

export const History = () => {
  return (
    <Container>
      <SocialBar />
      <Header />
      <Banner />
      <div className="content">
        <div className="split">
          <Title>
            <b>NOSSA HISTÓRIA</b>
          </Title>
          <p>
            A Gelius Indústria de Móveis, situada na cidade de Mirassol,
            interior de SP, foi fundada em 1984, graças ao espírito empreendedor
            da família Gelio. Tudo começou com uma oficina de carroceria,
            administrada por Alcides Gelio, o patriarca. Posteriormente, o
            empreendimento foi direcionado ao ramo moveleiro, passando a
            produzir camas e penteadeiras. Até que, a partir de 2001, a empresa
            inaugurou suas novas instalações, com a dimensão e estrutura física
            que tem atualmente. De lá pra cá, com mais de 34 anos de história,
            não paramos de crescer. Hoje, a empresa é referência nacional,
            produzindo e comercializando dormitórios completos: camas,
            cabeceiras para colchões box, cômodas, criados, roupeiros e uma
            linha completa de dormitórios infantojuvenis. São mais de 300
            produtos. Todos com design exclusivo. Atualmente, a Gelius tem 32000
            m² de área construída, às margens da rodovia Washington Luís, e
            conta com uma media de 350 colaboradores, que produzem cerca de
            60.000 peças por mês. Como resultado da preocupação com o
            investimento nos mais modernos equipamentos e treinamento de
            pessoal, a Gelius é reconhecida nacional e internacionalmente, com
            cerca de 4.000 clientes ativos em todo o território nacional, além
            de exportar para outros países.
          </p>
          <div className="img">
            <img
              src="https://www.gelius.com.br/site/images/sobrenos/sobre_nos.jpg"
              alt=""
            />
            <div className="blur"></div>
          </div>
        </div>

        <div className="split history-video">
          <p>
            A Gelius utiliza matéria-prima (MDF e MDP) totalmente reflorestada e
            investe nos detalhes de acabamento. Todos os produtos passam pela
            linha de pintura ultravioleta, que fixa com maior intensidade o
            brilho nos móveis, e possuem o sistema antirriscos: um verniz
            especial aplicado na linha de pintura que garante mais resistência a
            riscos. Somos pioneiros na montagem verticalizada de roupeiros,
            trazendo maior comodidade e segurança na hora de montar o produto. A
            empresa apresenta, ainda, diferenciais em assessório dos produtos.
            Na criação e utilização da sapata antiumidade, que evita o contato
            do móvel com o solo e a umidade, prevenindo o desgaste do produto e
            o quinto e sexto pés, colocados na parte central do estrado das
            camas de casal, com a finalidade de equilibrar e distribuir o peso,
            garantindo maior resistência ao estrado e durabilidade do móvel. A
            Gelius solidifica-se cada vez mais como uma das mais importantes
            indústrias de móveis do país, preparada para atender um mercado cada
            vez mais exigente e qualificado, buscamos sempre soluções
            inteligentes para sempre fazer sua família feliz.
          </p>
          <div className="img">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/nh1LjF9fgqo"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="blur"></div>
          </div>
        </div>

        <div className="exportation">
          <div className="content">
            <Title>
              <b>EXPORTAÇÃO</b>
            </Title>
            <p>
              A Gelius busca o crescimento internacional de sua marca,
              viabilizando a qualidade e exclusividade em produtos, mantendo a
              ética e a estratégia de vendas para seus Clientes em muitos
              países.
            </p>
          </div>
          <div className="img">
            <img
              src="https://www.gelius.com.br/site/images/nossa_historia/exportacao.png"
              alt=""
            />
            <div className="blur"></div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  )
}
